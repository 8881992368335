<template>
  <div>
    <div class="bg">
      <img src="@/assets/images/case_bg.png" alt="">
      <div class="content">
        <div class="title">成功案例</div>
        <div class="desc">欢迎与更多优秀企业加入AI资管</div>
        <!-- <div class="action">免费试用</div> -->
      </div>
    </div>
    <div class="case-share">
      <div class="title">案例分享</div>
      <a-carousel class="carousel-wrap" autoplay arrows>
        <div slot="prevArrow" class="custom-arrow" style="left: -50px">
          <img src="@/assets/images/case_left_arrow.png" alt="">
        </div>
        <div slot="nextArrow" class="custom-arrow" style="right: -50px">
          <img src="@/assets/images/case_right_arrow.png" alt="">
        </div>
        <div class="contaienr">
          <div class="l-bar">
            <img src="@/assets/images/case_1.png" alt="">
          </div>
          <div class="r-bar">
            <div class="list-wrap" v-for="(item, index) in data_list" :key="index">
              <div class="case-title-wrap">
                <span class="vertical-line"></span>
                <span class="case-title">{{ item.title }}</span>
              </div>
              <div class="case-content">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="contaienr">
          <div class="l-bar">
            <img src="@/assets/images/case_1.png" alt="">
          </div>
          <div class="r-bar">
            <div class="list-wrap" v-for="(item, index) in data_list" :key="index">
              <div class="case-title-wrap">
                <span class="vertical-line"></span>
                <span class="case-title">{{ item.title }}</span>
              </div>
              <div class="case-content">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </a-carousel>
    </div>
    <div class="kefu">
      <img src="@/assets/images/kefu.png" alt="">
    </div>
    <Footer />
  </div>
</template>

<script>

import Footer from '@/components/Footer'

export default {
  components: {
    Footer
  },
  data() {
    return {
      data_list: [
        {
          id: 0,
          title: '用户需求',
          content: '固定资产账实不符，盘点清查不准，责权不明确、审批流程不规范、各类资产标签繁多； 资产管理制度不标准，盘盈盘亏不明确，资产未及时巡检、维护，导致维修成本高。'
        },
        {
          id: 1,
          title: '解决方案',
          content: '使用资产数字化管理平台，统一标准、规范流程，梳理新旧资产数据，建立资产ID标签； 在二级单位试点打造“样板”，相互借鉴复制；完善资产管理知识库应用于线上和线下。'
        },
        {
          id: 2,
          title: '应用成效',
          content: '“AI资管”EAM实现资产全生命周期管理，帮助企业数字化转型，提高数字化竞争力； 提高管理效率50%，提高资产利用率40%，减少人工出错率30%，降低维修成本20%。'
        }
      ]
    }
  },
  mounted() {

  }
}
</script>

<style lang="less" scoped>
.bg{
  position: relative;
  img{
    width: 100%;
    display: block;
  }
  .content{
    position: absolute;
    top: 45%;
    left: 25%;
    .title{
      font-size: 4.6rem;
      font-weight: bold;
      color: #fff;
    }
    .desc{
      font-size: 2.4rem;
      font-weight: 100;
      color: #fff;
      margin: 5px 0 40px;
    }
    .action{
      width: 120px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-image: linear-gradient(to right, #3538FB, #92F0FE);
      border-radius: 8px;
      font-size: 1.6rem;
      color: #fff;
      cursor: pointer;
    }
    .action:hover{
      background-image: linear-gradient(to right, #2225DA, #92D3FE);
    }
  }
}
.case-share{
  padding: 4rem 0;
  .title{
    font-size: 3.6rem;
    font-weight: bold;
    margin-bottom: 4rem;
    text-align: center;
  }
  .carousel-wrap{
    width: 120rem;
    margin: 0 auto;
    background-color: #0c52bbd1;
    .contaienr{
      display: flex !important;
      align-items: center;
      padding: 5rem;
      .l-bar{

      }
      .r-bar{
        margin-left: 50px;
        .list-wrap{
          margin-bottom: 2rem;
          .case-title-wrap{
            display: flex;
            align-items: center;
            .vertical-line{
              width: 4px;
              height: 16px;
              display: inline-block;
              background-color: #5FCDFF;
              margin-right: 8px;
            }
            .case-title{
              font-size: 1.6rem;
              color: #fff;
            }
          }
          .case-content{
            color: #ffffff;
            line-height: 2.4rem;
            margin-top: 1rem;
          }
        }
        .list-wrap:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}
.carousel-wrap{
  .custom-arrow{
    width: 40px;
    height: 74px;
    font-size: 44px;
    background-color: rgba(31, 45, 61, 0);
    color: #000;
  }
  .custom-arrow:before{
    display: none;
  }
}
.kefu{
  display: flex;
  justify-content: center;
  img{
    width: 1200px;
    display: block;
  }
}
</style>